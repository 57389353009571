import React, { useState } from 'react'

export const JobForm = ({ id, title, location, type }) => {
  const initialFormData = {  
    "Job ID:": id,
    "Position": title,
    "Location": location,
    "Type": type,
    "First Name": '',
    "Last Name": '',
    "Resume": '',
  }
  const [formValues, setFormValues] = useState({ ...initialFormData })
  
  const handleChange = (event) => {
    if (event.target.name === "Resume") {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.files[0],
      })
    }
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    })
  }

  const encode = (data) => {
    const formData = new FormData()
    for (const key of Object.keys(data)) {
      formData.append(key, data[key])
    }
    return formData;
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const { name } = event.target
    const files = {}

    for (const field of event.target.elements) {
      if (field.type === 'file') {
        files[field.name] = field.files[0]
      }
    }

    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": name,
        ...formValues,
        ...files
      })
    })
    .then(res => res.status === 200 ? alert("Success! Thank you for applying to work with us.") : alert("Error!")) // fetch will blow right through this without checking for 200 status
    .catch(error => alert(error))
  }

  return (
    <form 
      data-netlify="true" 
      name="jobApplication" 
      netlify-honeypot="bot-field" 
      onSubmit={e => handleSubmit(e)}
    >
      <input type="hidden" name="form-name" value="jobApplication" />
      <input type="hidden" name="bot-field" />
      <label>
        First Name
        <input
          type="text"
          name="First Name"
          value={formValues['First Name']}
          onChange={e => handleChange(e)}
        />
      </label>
      <label>
        Last Name
        <input
          type="text"
          name="Last Name"
          value={formValues['Last Name']}
          onChange={e => handleChange(e)}
        />
      </label>
      <label>
        Upload Resume
        <input
          type="file"
          name="Resume"
          value={formValues['Resume']}
          onChange={e => handleChange(e)}
        />
      </label>
      <input type="submit" value="Submit" />
    </form>
  )
}
