import React, { useState } from 'react'
import styled from 'styled-components'
import { JobForm } from '../job-form'

const Container = styled.div`
  padding: 2.75rem 0;
  border-bottom: 3px solid hsla(${({theme}) => theme.teal.hsl}, 1);
  span {
    font-weight: 300;
  }
`
const Title = styled.p`
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: hsla(${({theme}) => theme.darkWhite.hsl}, 1);
  span {
    font-size: .75em;
  }
`
const Details = styled.p`
  margin-bottom: .75rem;
  font-weight: 900;
  color: hsla(${({theme}) => theme.darkWhite.hsl}, 1);  
  span {
    font-size: 1em;
    color: hsla(${({theme}) => theme.teal.hsl}, 1);
  }
`
const Description = styled.p`
  line-height: 1.5;
  margin-bottom: .75em;
  color: hsla(${({theme}) => theme.darkWhite.hsl}, 1);
`
const Button = styled.button`
  margin-bottom: ${({toggleForm}) => toggleForm ? '1rem': '0'};
`

export const JobPost = ({ id, title, location, type, description }) => {
  const [toggleForm, setToggleForm] = useState(false)

  const handleClick = event => {
    event.preventDefault()
    setToggleForm(!toggleForm)
  }

  return (
    <Container>
      <Title>{title} <span>id: {id}</span></Title>
      <Details>Location: <span>{location}</span> Type: <span>{type}</span></Details>
      <Description>{description || "Description unavailable."}</Description>
      <Button toggleForm={toggleForm} onClick={e => handleClick(e)}>{toggleForm ? 'Cancel' : 'Apply Now'}</Button>
      {toggleForm &&
        <JobForm
          id={id}
          title={title}
          location={location}
          type={type}
        />
      }
    </Container>
  )
}
