import * as React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from '../components/link'
import { H4, StyledHeading, P } from '../components/page-elements'
import { JobPost } from '../components/job-post'
// import MockData from '../components/job-post/mock-data.json'

const PageContainer = styled.div`
  padding-top: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 0;
  min-height: 75vh;
  max-width: 1024px;
  margin-right: auto;
  margin-bottom: 100px;
  margin-left: auto;

  @media(min-width: 768px) {
    margin-bottom: 150px;
  }
`
const Header = styled.header`
  padding-top: 150px;
  margin-bottom: 100px;
  >:first-child {
    margin-bottom: 25px;
  }
`
const Summary = styled(P)`
  line-height: 1.5;
  color: hsla(${({theme}) => theme.text}, 1);
  a {
    color: hsla(${({theme}) => theme.accent}, 1);
  }
`
const SubHeading = styled(H4)`
  margin-bottom: .5em;
  font-weight: 900;
  color: hsla(${({theme}) => theme.text}, 1);
`
const Message = styled(P)`
  color: hsla(${({theme}) => theme.text}, 1);
`
const ImageContainer = styled.div`
  margin-top: 100px;
`
const Careers = ({ location }) => {
  // eventually connect to CMS to allow client to add jobs
  const availableJobs = []

  return (
    <Layout
      title="Careers"
      pathname={location.pathname}
    >
      <PageContainer>
        <Header>
          <StyledHeading>Wanna Work With Us?</StyledHeading>
          <Summary>Shoot a resume to <Link to="mailto:info@eastoncraft.com">info@eastoncraft.com</Link> or click on "Apply Now" for any of the positions listed below. We will be contacting people soon.</Summary>      
        </Header>
        {availableJobs.length > 0 ?
          <>
          <SubHeading as="h2">Current Positions Available</SubHeading>
            {availableJobs.map(job => (
              <JobPost
                key={job.id}
                {...job}
              />
            ))}
          </>
        : <>
            <SubHeading as="p">Looks like we don't have any positions available right now.</SubHeading>
            <Message>Feel free to send us your resume though!</Message>
            <ImageContainer>
              <StaticImage
                src="../images/sprout-close-tilted.jpg"
                alt="Plant sprout close to the camera tilted to show roots."
              />
            </ImageContainer>
          </>}
      </PageContainer>
    </Layout>
  )
}

export default Careers
